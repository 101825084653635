/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const checkMessages = (e) => {
    var iframe = document.querySelector("#personio-iframe");
    var eventName = e.data[0];
    var data = e.data[1];
    switch (eventName) {
        case "setHeight":
            iframe.style.height = data + "px";
            window.scrollTo({ top: 0, behavior: "smooth" });
            break;
        default:
            break;
    }
};

export const onClientEntry = () => {
    window.addEventListener(
        "message",
        checkMessages,
        false
    );
};